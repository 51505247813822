<template>
  <div class="NavigationBar">
    <a href="/home">Home</a>
    <a href="mailto:dan@onblaze.net">Contact</a>
  </div>
</template>

<script>
export default {
  name: 'NavigationBar',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.NavigationBar {
 font-family: Avenir, Helvetica, Arial, sans-serif;
 vertical-align: middle;
 text-align: center;
 background-color: azure;
 padding: .5%;
}
.NavigationBar a {
padding: 5%;
font-size: large;
text-decoration: none;
color: lightslategray;
}
</style>
