

<template>
  <NavigationBar>
    
  </NavigationBar>

  <div class="container">
  <TitleFont msg="Dan Brooks"/>
  <TextList msg="..."/>
 <TextList msg="Network Engineer"/>

    <footer class="footer">
      <TextList msg="Made with ❤️ from Austin, Texas"/>
    </footer>
  </div>
</template>

<script>
import TitleFont from './components/Title.vue'
import TextList from './components/Text.vue'
import NavigationBar from './components/Navbar.vue'
export default {
  name: 'App',
  components: {
    TitleFont,
    TextList,
    NavigationBar
  }
}
</script>

<style>
body, html {
  height: 100%;
  margin: 0;
  background-color: #008AD7;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

.footer {
  background-color: #008AD7; /* Optional: add a background color to the footer */
  padding: 50px; /* Optional: add padding to the footer */
  text-align: center; /* Optional: center the text inside the footer */
  margin-top: auto;
}
</style>
