<template>
  <div class="text">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'TextList',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text {
 font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: azure;
  text-align: center;
  font-size: xx-small;
}
h1, textalign {
  margin-bottom: 1%;
  margin-top: 0%;
  align-content: center;

}
</style>
